<template>
  <h2 class="lg:text-52xl md:text-4xl md:w-full laptop:w-full fullhd:w-full md:leading-snug">
    <slot />
  </h2>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'SubTitle'
})
</script>

<style scoped>

</style>
